import {Component, OnInit} from '@angular/core';
import {UpToDateBuildService} from "./up-to-date.service";
import {MethodServices} from "../services/method-services";
import Swal from "sweetalert2";
import { SharingModule } from './sharing-module/sharing.module';

@Component({
  selector: 'app-up-to-date',
  template: '<span></span>',
  standalone: true,
  imports: [SharingModule]
})
export class UpToDateComponent implements OnInit {

  public buildIsUpToDate = true;

  constructor(private upToDateService: UpToDateBuildService) {
    upToDateService.buildIsUpToDate.subscribe(buildIsUpToDate => {
      this.buildIsUpToDate = buildIsUpToDate;
      if (!this.buildIsUpToDate) {
        Swal.fire({
          title: 'New Update Application',
          text: 'There is new update!, click button refresh to update your application',
          icon:'warning',
          confirmButtonText: 'Reload',
          customClass: {
            confirmButton: 'btn btn-primary confirm-btn me-3'
          }
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.reload()
          }
        })
      }
    });
  }

  ngOnInit() {
    console.log("starting on init for build number services")
  }
}
